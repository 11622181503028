import React from "react"

import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"

const AboutIndex = () => (
    <Layout>
        <SEO title="About"/>

        <PageTitle summary="With over 40+ years’ experience delivering a wide range of Architectural and Surveying services we offer multi-disciplinary solutions to meet individual needs."
                   title="About"/>

        <div className="body-wrap">
            <p>We are a small friendly, highly focused architectural practice based in Essex. While the practice is growing as a result of our ongoing success our priority is ensuring we maintain a professional and personal service to all our clients.</p>
            <p className="pb-10 lg:pb-16">Our Directors remain involved in all projects covering a diverse range of projects within public and private sectors and have many loyal long-standing Clients.</p>
            
            <p>Our core sectors include: -</p> 
            <ul className="list-none">
                <li className="font-bold">Residential</li>
                <li className="font-bold">Commercial</li>
                <li className="font-bold">Education</li>
                <li className="font-bold">Listed Buildings</li>
                <li className="font-bold">Conservation Areas</li>
            </ul>
        </div>
    </Layout>
)

export default AboutIndex
